import React, { forwardRef, type SVGProps } from "react";

type IconName =
  | "edit"
  | "info"
  | "home"
  | "cycle"
  | "person"
  | "people"
  | "devices"
  | "document"
  | "facebook"
  | "youtube"
  | "instagram"
  | "twitter"
  | "check"
  | "check-argumenter"
  | "mobile-phone"
  | "hand-with-hearts"
  | "fire"
  | "touchscreen"
  | "headphones-alt"
  | "promo"
  | "share"
  | "kid"
  | "envelope"
  | "lock"
  | "eye"
  | "eye-crossed"
  | "star"
  | "star-half"
  | "star-empty"
  | "list-border"
  | "unlock"
  | "play"
  | "pause"
  | "seek"
  | "volume"
  | "volume-muted"
  | "speed"
  | "heart"
  | "heart-border"
  | "shelf"
  | "shelf-outlined"
  | "bookshelf"
  | "history"
  | "gear"
  | "logout"
  | "user"
  | "user-alt"
  | "user-new"
  | "close"
  | "shopping-cart"
  | "menu"
  | "search"
  | "trash"
  | "hamburger"
  | "radio-button"
  | "radio-button-checked"
  | "logo"
  | "logo-alt"
  | "club-logo"
  | "club-circle-logo"
  | "chevron-up"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "popular"
  | "recommended"
  | "un-checked"
  | "checked"
  | "arrow"
  | "copy"
  | "ticket"
  | "tickets"
  | "infinity"
  | "clock"
  | "clock-small-one"
  | "clock-small-seven"
  | "calendar"
  | "credit-card"
  | "exclamation-circle";

interface Props extends SVGProps<SVGSVGElement> {
  accessibilityLabel?: string;
  icon: IconName;
}

export const NewIcon = forwardRef<SVGSVGElement, Props>(({ accessibilityLabel, icon, ...props }, ref) => {
  return (
    <svg {...props} ref={ref}>
      {accessibilityLabel && <title>{accessibilityLabel}</title>}
      <use href={`/sprite-20032025.svg#${icon}`} />
    </svg>
  );
});
