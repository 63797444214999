import { isServer } from "utils/runtime";
import { convertProductsToCart, convertProductToCartItemDetails } from "modules/cart/cart.utils";
import { Audiobook, Cart } from "resources/AudiotekaApi";

import { CartStoredItem } from "./cart.types";

class CartStore {
  private _db: Promise<IDBDatabase>;

  constructor() {
    if (isServer || !("indexedDB" in window)) {
      return;
    }

    const request = indexedDB.open("cart", 2);

    request.addEventListener("upgradeneeded", () => {
      const db = request.result;
      const tx = request.transaction;

      // v1
      const cartStore = db.objectStoreNames.contains("cart") ? tx.objectStore("cart") : db.createObjectStore("cart");

      // v2
      if (!cartStore.indexNames.contains("by_date")) {
        cartStore.createIndex("by_date", "createdAt");
      }
    });

    this._db = new Promise((resolve, reject) => {
      request.addEventListener("success", () => {
        resolve(request.result);
      });
      request.addEventListener("error", () => {
        reject(request.error);
      });
    });
  }

  public async getCart(): Promise<Cart> {
    const tx = (await this._db).transaction("cart", "readonly");

    const request = tx.objectStore("cart").index("by_date").openCursor(null, "prev"); // prev to sort from newest to older

    const result: CartStoredItem[] = [];

    return new Promise((resolve) => {
      request.addEventListener("success", () => {
        const cursor = request.result;

        if (cursor) {
          result.push(cursor.value);
          cursor.continue();
        } else {
          resolve(convertProductsToCart(result));
        }
      });
      request.addEventListener("error", () => resolve(convertProductsToCart([])));
    });
  }

  public async clearCart(): Promise<void> {
    const tx = (await this._db).transaction("cart", "readwrite");
    const store = tx.objectStore("cart");

    return new Promise((resolve, reject) => {
      tx.addEventListener("complete", () => resolve());
      tx.addEventListener("error", () => reject(tx.error));

      store.clear();
    });
  }

  public async addToCart(product: Audiobook): Promise<Cart> {
    const tx = (await this._db).transaction("cart", "readwrite");
    const store = tx.objectStore("cart");

    return new Promise((resolve, reject) => {
      tx.addEventListener("complete", () => resolve(this.getCart()));
      tx.addEventListener("error", () => reject(tx.error));

      const storedItem: CartStoredItem = {
        audiobook: product,
        createdAt: Date.now(),
        details: convertProductToCartItemDetails(product),
      };

      store.put(storedItem, product.id);
    });
  }

  public async removeFromCart(id: string): Promise<Cart> {
    const tx = (await this._db).transaction("cart", "readwrite");
    const store = tx.objectStore("cart");

    return new Promise((resolve, reject) => {
      tx.addEventListener("complete", () => resolve(this.getCart()));
      tx.addEventListener("error", () => reject(tx.error));

      store.delete(id);
    });
  }
}

export const cartStore = new CartStore();
