/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import classNames from "classnames";
import { useTranslations } from "next-intl";

import { Link } from "ui/link";
import { Container } from "ui/container";

import { MenuMobileItem } from "../menu-mobile";
import footerCss from "./footer.module.scss";
import css from "./catalog.module.scss";

export const Catalog = ({ catalogItems }: { catalogItems: MenuMobileItem[] }) => {
  const t = useTranslations();

  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <div className={css.footerCatalog}>
      <Container>
        <div className={footerCss.column}>
          <h3
            className={classNames(footerCss.title, {
              [footerCss.expandable]: true,
              [footerCss.expanded]: isVisible,
            })}
            onClick={() => setIsVisible((_isVisible) => !_isVisible)}
          >
            {t("footer.catalog")}
          </h3>
          <div
            className={classNames(
              footerCss.linkList,
              {
                [footerCss.expandable]: true,
                [footerCss.expanded]: isVisible,
              },
              css.list,
              css.expandable
            )}
          >
            {catalogItems.map(({ accent, name, route, params }) => (
              <Link
                key={`catalog-item-${name}`}
                className={classNames(css.item, footerCss.link, { [css.accent]: accent })}
                prefetch={false}
                route={route}
                params={params}
              >
                {name}
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};
