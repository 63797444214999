import { externalPaths } from "pathnames";

export const config = {
  columns: [
    {
      title: "Support",
      expandable: true,
      links: [
        {
          route: "helpCenter",
          text: "Hilfe",
        },
        {
          href: "https://audioteka.com/de/terms-of-use",
          text: "Audioteka's Nutzungsbedingungen",
        },
        {
          href: "http://static.audioteka.com/de/files/terms/Allgemeine_Geschaftsbedingungen.pdf",
          text: "AGB für Audioteka Abo",
        },
        {
          href: "https://web.audioteka.com/de/l/club-agb",
          text: "AGB für Audioteka Club",
        },
        {
          route: "privacyPolicy",
          text: "Datenschutzbestimmungen",
        },
        {
          className: "manageGdprButton",
          text: "Datenschutzeinstellungen",
        },
      ],
    },
    {
      title: "Kontakt",
      expandable: true,
      links: [
        {
          route: "helpCenterWithQuestion",
          params: {
            categoryId: "impressum",
            questionId: "help-center_impressum2",
          },
          text: "Impressum",
        },
      ],
    },
    {
      title: "Über uns",
      expandable: true,
      links: [
        { href: "https://web.audioteka.com/de/start.html", text: "Start" },
        { href: externalPaths.clubLandingPage.de, text: "Audioteka Club" },
        { href: "https://audioteka.com/welcome/", text: "Audioteka in anderen Ländern" },
      ],
    },
    {
      title: "Apps",
      apps: {
        android: "https://web.audioteka.com/de/l/android",
        ios: "https://web.audioteka.com/de/l/ios",
      },
    },
  ],
  social: [
    {
      href: "https://www.facebook.com/Audiotekager",
      icon: "facebook",
    },
    {
      href: "https://www.youtube.com/user/audiotekade",
      icon: "youtube",
    },
    {
      href: "https://www.instagram.com/audiotekade/",
      icon: "instagram",
    },
  ],
  copy: `Copyright © 2008-${new Date().getFullYear()} Audioteka Group Sp. z o.o.`,
};
