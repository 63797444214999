import { Catalog } from "resources/AudiotekaApi";
import { MenuMobileItem } from "layout/menu-mobile";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";

export const getCatalogItems = (catalog: Catalog) => {
  const catalogItems: MenuMobileItem[] = [];

  catalog._embedded["app:screen-section"].forEach((section) => {
    if (section.type === "item-sets") {
      section.elements.forEach((element) => {
        catalogItems.push({
          accent: true,
          name: element.label,
          ...deeplinkTranslator(element.deeplink, element.slug),
        });
      });
    } else if (section.type === "category-list") {
      section._embedded["app:category"].forEach((element) => {
        // Get only main categories
        if (element.parent_id) {
          return;
        }

        catalogItems.push({
          name: element.name,
          route: "category",
          params: {
            id: element.slug || element.id,
          },
        });
      });
    }
  });

  return catalogItems;
};
