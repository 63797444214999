import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface Toast {
  title: string;
  action?: {
    text: string;
    onClick(): void;
  };
  duration?: number;
  canClose?: boolean;
  message?: string;
  type?: "info" | "success" | "warning" | "error";
}

interface ToastStore {
  toasts: (Toast & { id: string })[];
  showToast: (toast: Toast) => void;
  closeToast: (id: string) => void;
}

export const useToast = create<ToastStore>()(
  devtools((setState) => ({
    toasts: [],

    showToast: (toast: Toast) => {
      const toastId = (Date.now() * Math.random()).toString();

      const newToast = {
        ...toast,
        id: toastId,
      };

      setState((state) => ({ toasts: [...state.toasts, newToast] }), undefined, "toast/show");
    },
    closeToast: (id: string) => {
      setState((state) => ({ toasts: state.toasts.filter((toast) => toast.id !== id) }), undefined, "toast/close");
    },
  }))
);
