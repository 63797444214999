import React from "react";

import { SearchScreen } from "resources/AudiotekaApi";
import { NewIcon } from "ui/NewIcon";

import { SearchResults } from "./search-results";
import css from "./recommendation-section.module.scss";

interface Props {
  icon: React.ComponentProps<typeof NewIcon>["icon"];
  section: SearchScreen["_embedded"]["app:screen-section"][0];
}

export function RecommendationSection({ icon, section }: Props) {
  return (
    <>
      <div className={css.titleBox}>
        <NewIcon icon={icon} width={24} height={24} />
        <div className={css.title}>{section.title}</div>
      </div>
      <SearchResults mobileLimit={6} limit={20} results={section._embedded["app:product"]} />
    </>
  );
}
