import React from "react";
import { useTranslations } from "next-intl";

import type { Product } from "resources/AudiotekaApi";
import { usePlayer } from "modules/player";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { NewIcon } from "ui/NewIcon";

import { Teaser } from "./teaser";

export function PlayButton({ product }: { product: Product }) {
  const player = usePlayer();
  const t = useTranslations("teaser");

  const linkProps = deeplinkTranslator(product.deeplink, product.slug);
  const isPlayable = linkProps.route === "audiobook";

  if (!isPlayable) return null;

  return (
    <Teaser.ActionButton onClick={() => player.load(product.slug, { autoPlay: true })} ariaLabel={t("play")}>
      <NewIcon icon="play" width="20" height="20" />
    </Teaser.ActionButton>
  );
}
