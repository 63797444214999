import React, { useRef, useState } from "react";
import { useTranslations } from "next-intl";
import dynamic from "next/dynamic";

import { Link } from "ui/link";
import { NewIcon } from "ui/NewIcon";
import { useUser } from "store/useUser";
import { useCart } from "modules/cart/cart.hooks";
import { useLaunchTestDomain } from "utils/useLaunchTestDomain";

import IconWithBadge from "./icon-with-badge/icon-with-badge";
import css from "./icon-buttons.module.scss";

const MiniCart = dynamic(() => import("modules/cart/mini-cart"), { ssr: false });

export function CartButton() {
  const visibleRef = useRef<number | NodeJS.Timeout>();

  const cart = useCart();
  const isAlphaTester = useUser((state) => state.isAlphaTester);
  const t = useTranslations();
  const launchTestDomain = useLaunchTestDomain();

  const [isVisible, setVisible] = useState(false);

  const mouseEnter = () => {
    clearTimeout(visibleRef.current);
    setVisible(true);
  };

  const mouseLeave = () => {
    visibleRef.current = setTimeout(() => {
      setVisible(false);
    }, 250);
  };

  if (isAlphaTester) {
    return (
      <div className={css.cartButton}>
        <Link
          className={css.menuLink}
          route="cart"
          title={t("navigation.cart")}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        >
          <IconWithBadge value={cart.items.length}>
            <NewIcon className={css.icon} icon="shopping-cart" width="24" height="24" />
          </IconWithBadge>
        </Link>
        <MiniCart open={isVisible} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />
      </div>
    );
  }

  return (
    <Link className={css.menuLink} href={`${launchTestDomain}/[locale]/cart`} title={t("navigation.cart")}>
      <IconWithBadge value={cart.items.length}>
        <NewIcon className={css.icon} icon="shopping-cart" width="24" height="24" />
      </IconWithBadge>
    </Link>
  );
}
