import { useLocale } from "next-intl";

const config = {
  pl: {
    description:
      "Audioteka to wiodąca platforma oferująca tysiące tytułów audiobooków, podcastów i audioseriali. Posiadamy największą bazę audiobooków w języku polskim. Oprócz zakupu pojedynczych tytułów, oferujemy możliwość wykupienia abonamentu, który umożliwia dostęp do bogatej biblioteki audiobooków, podcastów i audioseriali w naszej aplikacji mobilnej. Nasza aplikacja pozwala słuchać treści w dowolnym miejscu i czasie, z możliwością słuchania offline. Audioteka jest dostępna zarówno na urządzeniach z systemem Android, jak i iOS.",
    alternateName: ["audioteka.pl", "audioteka - audiobooki", "audioteka pl"],
    url: "https://audioteka.com/pl/",
    sameAs: [
      "https://www.facebook.com/audioteka/",
      "https://www.instagram.com/audioteka/",
      "https://www.tiktok.com/@audioteka.pl",
      "https://x.com/audioteka",
      "https://www.youtube.com/user/audiotekapl",
      "https://www.linkedin.com/company/audioteka-pl/",
      "https://play.google.com/store/apps/details?id=com.audioteka&hl=pl",
      "https://apps.apple.com/pl/app/audioteka-audiobooki-podcasty/id1107917398?l=pl",
    ],
    contactPoint: {
      telephone: "+48 22 470 09 94",
      email: "hello@audioteka.com",
      areaServed: "PL",
      availableLanguage: ["Polish"],
    },
    address: {
      streetAddress: "ul. Żwirki i Wigury 16",
      addressLocality: "Warszawa",
      postalCode: "02-092",
      addressCountry: "PL",
    },
  },

  lt: {
    description:
      "Audioteka yra pirmaujanti platforma, siūlanti tūkstančius audioknygų, tinklalaidžių ir garso serialų. Be galimybės įsigyti atskirus kūrinius, mūsų prenumeratos modelis suteikia prieigą prie didelės garso turinio bibliotekos, kurią galima pasiekti bet kur ir bet kada. Mūsų lengvai naudojama programėlė, prieinama „Android“ ir „iOS“ įrenginiams, taip pat siūlo galimybę klausytis turinio neprisijungus. Audioteka yra idealus draugas kiekvienam, kuris mėgsta garso pramogas.",
    alternateName: ["audioteka.lt", "audioteka lt"],
    url: "https://audioteka.com/lt/",
    sameAs: [
      "https://www.facebook.com/audioteka.lt",
      "https://www.youtube.com/@AudiotekaLt",
      "https://www.instagram.com/audioteka.lt/",
      "https://play.google.com/store/apps/details?id=com.audioteka&hl=lt",
      "https://apps.apple.com/lt/app/audioteka/id1107917398?l=lt",
    ],
    contactPoint: {
      telephone: "+370 (5) 272 1640",
      email: "pagalba@audioteka.lt",
      contactType: "Customer Service",
      areaServed: "LT",
      availableLanguage: ["Lithuanian"],
    },
    address: {
      streetAddress: "J. Basanavičiaus g. 7",
      addressLocality: "Vilnius",
      postalCode: "01118",
      addressCountry: "LT",
    },
  },

  de: {
    description:
      "Audioteka ist eine führende Plattform, die Tausende von Hörbüchern, Podcasts und Audio-Serien bietet. Neben dem Einzelkauf von Titeln ermöglicht unser Abonnementmodell den Zugriff auf eine umfangreiche Bibliothek von Audioinhalten, die jederzeit und überall verfügbar sind. Unsere benutzerfreundliche App, verfügbar für Android und iOS, bietet auch die Möglichkeit, Inhalte offline zu hören. Audioteka ist der perfekte Begleiter für alle, die Audio-Unterhaltung lieben.",
    alternateName: ["audioteka.de", "audioteka de"],
    url: "https://audioteka.com/de/",
    sameAs: [
      "https://www.facebook.com/Audiotekager",
      "https://www.youtube.com/user/audiotekade",
      "https://www.instagram.com/audiotekade/",
      "https://play.google.com/store/apps/details?id=com.audioteka&hl=de",
      "https://apps.apple.com/de/app/audioteka-h%C3%B6rb%C3%BCcher-streaming/id1107917398",
    ],
    contactPoint: {
      email: "hello@audioteka.com",
      areaServed: "DE",
      availableLanguage: ["German"],
    },
    address: {
      streetAddress: "ul. Żwirki i Wigury 16",
      addressLocality: "Warszawa",
      postalCode: "02-092",
      addressCountry: "PL",
    },
  },

  cz: {
    description:
      "Audioteka je přední platforma nabízející tisíce audioknih, podcastů a audio seriálů. Kromě možnosti individuálního nákupu titulů náš model předplatného umožňuje přístup k rozsáhlé knihovně audioobsahu dostupného kdykoliv a kdekoliv. Naše snadno ovladatelná aplikace, dostupná pro Android a iOS, navíc nabízí možnost poslechu offline. Audioteka je ideálním společníkem pro každého, kdo miluje audio zábavu.",
    alternateName: ["audioteka.cz", "audioteka cz", "audioteka - audiokniha"],
    url: "https://audioteka.com/cz/",
    sameAs: [
      "https://www.facebook.com/audioteka.cz",
      "https://www.youtube.com/audiotekacz",
      "https://www.instagram.com/audioteka_czsk/",
      "https://x.com/audioteka_cz",
      "https://play.google.com/store/apps/details?id=com.audioteka&hl=cs",
      "https://apps.apple.com/cz/app/audioteka/id1107917398?l=cs",
    ],
    contactPoint: {
      telephone: "+420 597 490 446",
      email: "podpora@audioteka.com",
      areaServed: "CZ",
      availableLanguage: ["Czech"],
    },
    address: {
      streetAddress: "Smetanovo náměstí 222/8",
      addressLocality: "Moravská Ostrava",
      postalCode: "702 00",
      addressCountry: "CZ",
    },
  },

  sk: {
    description:
      "Audioteka je popredná platforma ponúkajúca tisíce audiokníh, podcastov a audio seriálov. Okrem možnosti individuálneho nákupu diel náš model predplatného umožňuje prístup k rozsiahlej knižnici audio obsahu, ktorý je dostupný kedykoľvek a kdekoľvek. Naša používateľsky prívetivá aplikácia, dostupná pre Android a iOS, ponúka tiež možnosť počúvania obsahu offline. Audioteka je ideálnym spoločníkom pre každého, kto miluje audio zábavu.",
    alternateName: ["audioteka.sk", "audioteka sk"],
    url: "https://audioteka.com/sk/",
    sameAs: [
      "https://www.facebook.com/audioteka.sk",
      "https://www.youtube.com/channel/UCwVm0-BIv-18fu2jTVQIB5Q",
      "https://www.instagram.com/audioteka_czsk/",
      "https://x.com/audioteka_sk",
      "https://apps.apple.com/sk/app/audioteka/id1107917398?l=sk",
      "https://play.google.com/store/apps/details?id=com.audioteka&hl=sk",
    ],
    contactPoint: {
      telephone: "+420 597 490 446",
      email: "podpora@audioteka.com",
      contactType: "Customer Service",
      areaServed: "SK",
      availableLanguage: ["Slovakian"],
    },
    address: {
      streetAddress: "Smetanovo náměstí 222/8",
      addressLocality: "Moravská Ostrava",
      postalCode: "702 00",
      addressCountry: "CZ",
    },
  },
};

const logoUrl = `${process.env.SITE_URL}/audioteka_logo.png`;

export function useOrganizationSchema() {
  const locale = useLocale();

  return () => ({
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Audioteka",
    description: config[locale].description,
    alternateName: config[locale].alternateName,
    url: config[locale].url,
    logo: logoUrl,
    sameAs: config[locale].sameAs,
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Customer Service",
      ...config[locale].contactPoint,
    },
    address: {
      "@type": "PostalAddress",
      ...config[locale].address,
    },
    brand: {
      "@type": "Brand",
      name: "Audioteka",
      logo: logoUrl,
    },
  });
}
