import gtm from "react-gtm-module";
import sha1 from "crypto-js/sha1";
import sha256 from "crypto-js/sha256";

import type { User } from "resources/AudiotekaApi";
import { isServer } from "utils/runtime";

export function gtmUser(user: User) {
  gtm.dataLayer({
    dataLayer: {
      user: {
        audioteka_club_member: user.has_club,
        em: sha256(user.email).toString(),
        iuid: user.id,
        optin: user.newsletter,
        registered: new Date(user.created_at).getTime(),
        tracking_id: user.tracking_id,
        uid: sha1(user.id).toString(),
        web_version: 1,
      },
    },
  });
}

export function shouldBeAlphaTester() {
  if (isServer) {
    return false;
  }

  const alphaTest = new URL(window.location.href).searchParams.get("alphaTest");

  if (alphaTest) {
    localStorage.setItem("alphaTest", alphaTest);
  }

  return localStorage.getItem("alphaTest") === "true";
}
