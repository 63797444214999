import React from "react";
import classNames from "classnames";

import { InternalLink } from "ui/link/internal-link";
import type { Product } from "resources/AudiotekaApi";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";

import css from "./teaser.module.scss";

function Container({ children, colorless }: { children: React.ReactNode; colorless?: boolean }) {
  return <div className={classNames("adtk-item", css.teaser, { [css.teaser__colorless]: colorless })}>{children}</div>;
}

interface LinkProps {
  children: React.ReactNode;
  onClick?: () => void;
  product: Product;
  strict?: boolean;
}

function Link({ children, onClick, product, strict = true }: LinkProps) {
  const linkProps = deeplinkTranslator(product.deeplink, product.slug);
  const strictParams: Record<string, string> = strict ? {} : { "not-strict": "true" };

  return (
    <InternalLink
      className={css.link}
      aria-label={`${product.name}. ${product.description}`}
      onClick={onClick}
      route={linkProps.route!}
      params={{ ...linkProps.params, ...strictParams }}
    >
      {children}
    </InternalLink>
  );
}

function Cover({ alt, lazyLoading, url }: { alt: string; lazyLoading?: boolean; url: string }) {
  return (
    <div className={css.cover}>
      <img
        className={css.coverImage}
        loading={lazyLoading ? "lazy" : undefined}
        src={`${url}?w=360&auto=format`}
        alt={alt}
      />
    </div>
  );
}

function Title({ title }: { title: string }) {
  return <p className={css.title}>{title}</p>;
}

function Author({ author }: { author: string | null }) {
  if (!author) {
    return null;
  }

  return <p className={css.author}>{author}</p>;
}

interface ActionButtonProps {
  ariaLabel: string;
  onClick: () => void;
  children: React.ReactNode;
}

function ActionButton({ ariaLabel, onClick, children }: ActionButtonProps) {
  return (
    <button className={css.actionBtn} onClick={onClick} type="button" aria-label={ariaLabel}>
      {children}
    </button>
  );
}

export const Teaser = {
  Container,
  ActionButton,
  Author,
  Cover,
  Link,
  Title,
};
