import React from "react";

import { Link } from "ui/link";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { objectKeys } from "utils/tsUtils";
import { pathnames, pathnamesKey } from "pathnames";

import css from "./title.module.scss";

type Props = {
  title?: string;
  more?: {
    link: string;
    text: string;
  };
};

const getLinkPropsFromMoreLink = (moreLink: string) => {
  const link = deeplinkTranslator(moreLink);

  if ("route" in link) {
    return link;
  }

  const siteUrl = process.env.SITE_URL;
  const isInternal = link.href.startsWith(siteUrl);

  if (!isInternal) {
    return link.href;
  }

  const path = link.href.replace(siteUrl, "");
  const [, pathLocale, pathBaseRoute, rest] = path.split("/");

  const pathname = objectKeys(pathnames).find((key) =>
    pathnames[key][pathLocale].includes(`/${pathBaseRoute}${rest ? "/" : ""}`)
  );

  const route = objectKeys(pathnamesKey).find((pathanameKey) => pathnamesKey[pathanameKey] === pathname);

  if (route) {
    return {
      route,
      params: {
        id: rest,
      },
    };
  }

  return link.href;
};

function MoreLink({ more }: Pick<Props, "more">) {
  if (!more) return null;

  const link = getLinkPropsFromMoreLink(more.link);

  if (typeof link === "string") {
    return <Link href={link}>{more.text} &gt; </Link>;
  }

  return (
    <Link className={css.more} route={link.route} params={link.params}>
      {more.text} &gt;
    </Link>
  );
}

export function Title({ title, more }: Props) {
  if (!title) return null;

  return (
    <div className={css.titleContainer}>
      <h2 className={css.title}>{title}</h2>
      <MoreLink more={more} />
    </div>
  );
}
