import React from "react";
import dynamic from "next/dynamic";
import { useLocale } from "next-intl";

import { isServer } from "utils/runtime";
import { useUser } from "store/useUser";

import { useConsent } from "../../useConsent";

import { LOTTERY_INFO_ITEM, LOTTERY_START_TIME, LOTTERY_END_TIME } from "../consts";

const LotteryInfoModalFull = dynamic(() => import("./lottery-info-modal"), {
  ssr: false,
});

function isLotteryActive() {
  const now = Date.now();
  return now >= LOTTERY_START_TIME && now <= LOTTERY_END_TIME;
}

export function LotteryInfoModal() {
  const user = useUser((state) => state.data);
  const isUserFetched = useUser((state) => state.status === "fetched");
  const locale = useLocale();
  const hasConsent = useConsent();

  if (isServer) return null;
  if (!hasConsent) return null;
  if (locale !== "pl") return null;
  if (!isUserFetched || user?.isClubMember) return null;
  if (!isLotteryActive()) return null;

  const count = localStorage.getItem(LOTTERY_INFO_ITEM);

  if (!count) return <LotteryInfoModalFull count={0} />;

  if (JSON.parse(count) >= 3) return null;

  return <LotteryInfoModalFull count={JSON.parse(count)} />;
}
