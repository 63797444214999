import React from "react";
import debounce from "lodash/debounce";

import type { Product } from "resources/AudiotekaApi/AudiotekaApi.types";
import SearchService from "modules/Search/SearchService";

export const useSearchTerm = () => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState<Product[]>([]);

  const fetchResults = React.useCallback(
    debounce(async (value: string) => {
      if (!value) {
        setResults([]);
        return;
      }

      const firstPage = await SearchService.search(value);
      setResults(firstPage._embedded["app:product"] ?? []);
    }, 250),
    []
  );

  const onSearchTermChange = React.useCallback((value: string) => {
    if (value.length > 120) return;

    setSearchTerm(value);
    fetchResults(value);
  }, []);

  const clearResults = React.useCallback(() => {
    setSearchTerm("");
    setResults([]);
  }, []);

  return { searchTerm, onSearchTermChange, results, clearResults };
};
