import { useEffect } from "react";

import { useUser } from "store/useUser";
import type { User } from "resources/AudiotekaApi";

import { userSyncChannel } from "utils/userSyncChannel";

type UserSyncEvent = MessageEvent<
  | {
      actionType: "signIn";
      user: User;
    }
  | { actionType: "signOut" }
  | { actionType: "update"; user: Partial<User> }
>;

export function useUserSync() {
  const setUser = useUser((state) => state.setUser);
  const updateUser = useUser((state) => state.updateUser);

  useEffect(() => {
    function handleMessage({ data }: UserSyncEvent) {
      // eslint-disable-next-line default-case
      switch (data.actionType) {
        case "signIn":
          setUser(data.user, false);
          break;
        case "signOut":
          setUser(null, false);
          break;
        case "update":
          updateUser(data.user, false);
      }
    }

    userSyncChannel.addEventListener("message", handleMessage);

    return () => {
      userSyncChannel.removeEventListener("message", handleMessage);
    };
  }, []);
}
