import { useState, useEffect } from "react";

export const useConsent = () => {
  const [hasConsent, setConsent] = useState(false);

  useEffect(() => {
    window.WP.push(() => {
      window.WP.gdpr.runAfterConsent(() => {
        setConsent(true);
      });
    });
  }, []);

  return hasConsent;
};
