import { Category, LicenseChannels } from "resources/AudiotekaApi";
import { BreadcrumbsItem } from "ui/breadcrumbs/breadcrumbs.types";
import { parsePrice } from "utils/string";

export type PricesWithCurrency = {
  base: string | null;
  club: string | null;
  discount: string | null;
  final: string | null;
  lowest: string | null;
};

type Prices = {
  base: number;
  final: number;
  withCurrency: PricesWithCurrency;
};

type ProductPrices = {
  price: string;
  price_for_subscribers: string;
  discount_price: string;
  lowest_price: string;
};

export function getIsShared(licenses?: LicenseChannels) {
  return licenses?.shared.state === "CAN_LISTEN";
}

export function getIsOnShelf(licenses?: LicenseChannels) {
  return licenses?.oneoff.state === "CAN_LISTEN" || getIsShared(licenses);
}

function convertCurrencyToNumber(currency: string): number {
  const normalizedCurrency = currency.replace(",", ".");
  const numericString = normalizedCurrency.replace(/[^\d.]/g, "");
  return parseFloat(numericString);
}

export function getPrices(productPrices: ProductPrices, licenses?: LicenseChannels, isClubMember = false): Prices {
  const { oneoff } = licenses || {};

  let { price: base, price_for_subscribers: club, discount_price: discount, lowest_price: lowest } = productPrices;

  if (oneoff?.state === "CAN_BUY") {
    base = oneoff.price;
    lowest = oneoff.lowest_price;

    if (oneoff.available_discount === "SUBSCRIBER_DISCOUNT") {
      club = oneoff.discount_price;
    } else {
      discount = oneoff.discount_price;
    }
  }

  const allPrices: string[] = [base, discount as string, isClubMember && (club as string)]
    .filter(Boolean)
    .sort((a, b) => {
      const aPrice = convertCurrencyToNumber(a);
      const bPrice = convertCurrencyToNumber(b);
      return aPrice > bPrice ? 1 : bPrice > aPrice ? -1 : 0;
    });
  const final = allPrices[0]; // lowest price available

  return {
    base: base ? parsePrice(base) : null,
    final: final ? parsePrice(final) : null,
    withCurrency: {
      base,
      club,
      discount,
      final,
      lowest,
    },
  };
}

export function cutText(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3).replace(/ *$/, "...");
  }

  return text;
}

const getCategory = (categories: Category[], categoryId: string | null) =>
  categories.find((_category) => _category.id === categoryId);

export const getBreadcrumbs = (categoryId: string, categories: Category[]) => {
  const category = getCategory(categories, categoryId);

  if (!category) return [];

  const breadcrumbs: BreadcrumbsItem[] = [
    {
      name: category.name,
      link: {
        route: "category",
        params: {
          id: category.slug || category.id,
        },
      },
    },
  ];

  if (category.parent_id) {
    const parentCategory = getCategory(categories, category.parent_id);

    if (parentCategory) {
      breadcrumbs.unshift({
        name: parentCategory.name,
        link: {
          route: "category",
          params: {
            id: parentCategory.slug || parentCategory.id,
          },
        },
      });
    }
  }

  return breadcrumbs;
};
