// eslint-disable-next-line import/no-unresolved
import { useReCaptcha } from "next-recaptcha-v3";
import { useCallback } from "react";
import { useLocale, useTranslations } from "next-intl";
import gtm from "react-gtm-module";
import * as Sentry from "@sentry/nextjs";

import { useToast } from "store/useToast";
import { getDeviceID } from "modules/App/deviceId";
import { useSignInRedirectParams } from "modules/App/useSignInRedirectParams";
import { getUser, loginUser, logoutUser, registerUser } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";
import { useRouter } from "utils/next-router-provider";
import { useUser } from "store/useUser";
import { buildLink } from "utils/buildLink";
// pewnie trzeba będzie przenieść do src/utils
import { gtmUser } from "store/useUser/utils";
import { UserErrors } from "store/useUser/types";

export const useAuth = () => {
  const locale = useLocale();
  const t = useTranslations();
  const router = useRouter();
  const showToast = useToast((state) => state.showToast);
  const { executeRecaptcha } = useReCaptcha();
  const { signInRedirectParams } = useSignInRedirectParams();
  const userStatus = useUser((state) => state.status);
  const setUser = useUser((state) => state.setUser);
  const setUserStatus = useUser((state) => state.setStatus);

  const { catalogId } = getLocationInfo(locale);

  const signIn = async (email: string, password: string) => {
    setUserStatus("pending");

    try {
      const recaptchaToken = await executeRecaptcha("login");
      await loginUser({ deviceId: getDeviceID(), email, password, catalogId, recaptchaToken });

      const { data: user } = await getUser();
      setUser(user);

      gtm.dataLayer({
        dataLayer: {
          event: "login",
          login_method: "standard",
        },
      });
      gtmUser(user);
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        duration: 10000,
        title: t("auth.sign_in.error_title"),
        message: t("auth.sign_in.error_message"),
        type: "error",
      });

      setUserStatus("fetched");
    }
  };

  const signUp = async (formData: FormData) => {
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    const termsOfService = formData.get("terms_of_service") === "1";
    const marketingConsent = formData.get("marketing_consent") === "1";
    const errors: UserErrors = {};

    setUserStatus("pending");

    try {
      await registerUser({ email, password, termsOfService, marketingConsent, catalogId });

      gtm.dataLayer({
        dataLayer: {
          event: "sign_up_web",
        },
      });

      await signIn(email, password);

      if (locale === "pl") {
        router.push(buildLink("joinAudiotekaClub", locale, signInRedirectParams));
      }
    } catch (error) {
      Sentry.captureException(error);
      showToast({
        duration: 10000,
        title: t("auth.sign_up.error_title"),
        message: t("auth.sign_up.error_message"),
        type: "error",
      });

      if ("response" in error) {
        const response = await error.response.data;
        const errorList = response._embedded?.["app:error"] || [];

        errorList.forEach(({ property, code }) => {
          errors[property] = `auth.sign_up.error.${property}.${code.toLowerCase()}`;
        });
      }

      setUserStatus("fetched");
    }

    return Object.keys(errors).length ? errors : null;
  };

  const signOut = async () => {
    try {
      await logoutUser(catalogId);
      setUser(null);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const initAuthCheck = useCallback(async () => {
    try {
      if (userStatus !== "idle") return;
      setUserStatus("pending");

      const { data: user } = await getUser();

      setUser(user, false);
    } catch {
      setUser(null);
    }
  }, [setUser, setUserStatus, userStatus]);

  return { signIn, signUp, signOut, initAuthCheck };
};
