import React from "react";
import { useTranslations } from "next-intl";

import { Link } from "ui/link";
import { NewIcon } from "ui/NewIcon";
import { useFavourites } from "modules/favourites/favourites.provider";

import IconWithBadge from "./icon-with-badge/icon-with-badge";
import { trackUpperRightMenuClickEvent } from "./trackUpperRightMenuClickEvent";
import css from "./icon-buttons.module.scss";

export const FavouriteButton = () => {
  const t = useTranslations();
  const favourites = useFavourites();

  return (
    <Link
      className={css.menuLink}
      route="favourites"
      title={t("navigation.favourites")}
      onClick={() => trackUpperRightMenuClickEvent("favourites")}
    >
      <IconWithBadge value={favourites.count}>
        <NewIcon className={css.icon} icon="heart" width="24" height="24" />
      </IconWithBadge>
    </Link>
  );
};
