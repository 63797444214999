/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

import { Loader } from "../loader";

import type { ButtonColor } from "./button.types";
import css from "./button.module.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor;
  outlined?: boolean;
  loading?: boolean;
  fluid?: boolean;
}

export function Button({
  children,
  className,
  color = "blue",
  outlined,
  type = "button",
  loading = false,
  fluid = false,
  ...props
}: Props) {
  const classes = classNames(className, css.button, css[color], {
    [css.outlined]: outlined,
    [css.fluid]: fluid,
    [css.loading]: loading,
  });

  return (
    <button className={classes} type={type} {...props}>
      {loading && (
        <span className={css.loaderWrapper}>
          <Loader className={css.loader} />
        </span>
      )}
      <span className={css.content}>{children}</span>
    </button>
  );
}
