"use client";

import React from "react";
import { Toast } from "radix-ui";

import { useToast } from "store/useToast";

import { Toast as ToastRadix, ToastViewport } from "./toast";

export function ToastProvider() {
  const toasts = useToast((state) => state.toasts);

  if (toasts.length === 0) return null;

  return (
    <Toast.Provider>
      {toasts.map((toast) => (
        <ToastRadix key={toast.id} {...toast} />
      ))}
      <ToastViewport />
    </Toast.Provider>
  );
}
