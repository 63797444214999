/* eslint-disable consistent-return */
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { isServer } from "utils/runtime";

import css from "./tooltip.module.scss";

interface Props extends PropsWithChildren {
  trigger: React.RefObject<HTMLElement | SVGSVGElement>;
  distance?: number;
  paddingBlock?: number;
}

export function Tooltip({ children, distance = 7, trigger, paddingBlock }: Props) {
  const portalRoot = useMemo(() => (isServer ? null : document.createElement("div")), []);
  const rootRef = useRef<HTMLDivElement>(null);

  const [isVisible, setVisible] = useState(false);
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    document.body.append(portalRoot);
    setMounted(true);

    return () => {
      setMounted(false);
      document.body.removeChild(portalRoot);
    };
  }, []);

  useEffect(() => {
    if (trigger.current) {
      const hMouseEnter = () => setVisible(true);
      const hMouseLeave = () => setVisible(false);

      trigger.current.addEventListener("mouseenter", hMouseEnter);
      trigger.current.addEventListener("mouseleave", hMouseLeave);
      // trigger.current.addEventListener("click", () => setVisible((visible) => !visible));

      return () => {
        if (trigger.current) {
          trigger.current.removeEventListener("mouseenter", hMouseEnter);
          trigger.current.removeEventListener("mouseleave", hMouseLeave);
        }
      };
    }

    return () => undefined;
  }, [trigger.current, rootRef.current]);

  useEffect(() => {
    function calculatePosition() {
      const { height: rootHeight, width: rootWidth } = rootRef.current.getBoundingClientRect();
      const {
        height: triggerHeight,
        left: triggerLeft,
        top: triggerTop,
        width: triggerWidth,
      } = trigger.current.getBoundingClientRect();
      const { scrollX, scrollY } = window;
      const { scrollWidth: windowWidth } = document.body;

      let left = triggerLeft + scrollX;
      let top = triggerTop - rootHeight + scrollY - distance;

      if (left > windowWidth - rootWidth - 15) {
        left = Math.max(15, triggerLeft + triggerWidth - rootWidth);
      }

      if (top - scrollY < 15) {
        top = triggerTop + triggerHeight + scrollY + distance;
      }

      rootRef.current.style.top = `${top}px`;
      rootRef.current.style.left = `${left}px`;
    }

    if (isVisible && trigger.current && rootRef.current) {
      calculatePosition();

      const resizeObserver = new ResizeObserver(() => calculatePosition());

      resizeObserver.observe(document.body);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [distance, isVisible, trigger.current, rootRef.current]);

  return isMounted
    ? ReactDOM.createPortal(
        <div
          className={classNames(css.tooltip, {
            [css.tooltip__visible]: isVisible,
          })}
          style={{ "--paddingBlock": paddingBlock } as React.CSSProperties}
          ref={rootRef}
        >
          {children}
        </div>,
        portalRoot
      )
    : null;
}
