import React from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Link } from "ui/link";
import { NewIcon } from "ui/NewIcon";

import { MenuMobileItem } from "../../menu-mobile";
import css from "./catalog-dropdown.module.scss";

interface Props {
  isVisible: boolean;
  items: MenuMobileItem[];
  containerRef: React.RefObject<HTMLDivElement>;
  onToggle: () => void;
}

export const CatalogDropdown = ({ items, isVisible, containerRef, onToggle }: Props) => {
  const t = useTranslations();

  return (
    <div className={classNames(css.catalogDropdown, { [css.open]: isVisible })} ref={containerRef}>
      <div className={css.list} style={{ "--items-length": items.length } as React.CSSProperties}>
        {items.map(({ accent, name, route, params }) => (
          <Link
            className={classNames(css.item, { [css.accent]: accent })}
            route={route}
            params={params}
            key={`catalog-${name}`}
          >
            {name}
          </Link>
        ))}
      </div>
      <div className={css.footer}>
        <Link className={css.footerItem} route="catalog">
          <NewIcon icon="menu" width="20" height="20" />
          {t("navigation.all_categories")}
        </Link>
        <button type="button" className={css.collapseButton} onClick={onToggle}>
          {t("navigation.collapse")}
          <NewIcon icon="chevron-up" width="16" height="16" />
        </button>
      </div>
    </div>
  );
};
