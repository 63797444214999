import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";

import { isServer } from "utils/runtime";
import { NewIcon } from "ui/NewIcon";

import css from "./scroll-top.module.scss";

const ScrollTop = () => {
  const root = useMemo(() => (isServer ? null : document.createElement("div")), []);

  const [isVisible, setVisible] = useState(false);
  const [isMounted, setMounted] = useState(false);

  const scrollTop = useCallback(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  useEffect(() => {
    document.body.appendChild(root);
    setMounted(true);

    return () => {
      setMounted(false);
      root.remove();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isVisible && window.scrollY > 50) {
        setVisible(true);
      } else if (isVisible && window.scrollY < 50) {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return isMounted
    ? createPortal(
        <button className={classNames(css.button, { [css.visible]: isVisible })} type="button" onClick={scrollTop}>
          <NewIcon icon="chevron-up" width="28" height="28" />
        </button>,
        root
      )
    : null;
};

export default ScrollTop;
