import React from "react";
import { Toast as ToastPrimitive } from "radix-ui";
import classNames from "classnames";

import { type Toast as TToast, useToast } from "store/useToast";

import { Container } from "../container";
import { ButtonPrimitive } from "../button/button-primitive";
import { Button } from "../button/button";
import { NewIcon } from "../NewIcon";
import { ToastIcon } from "./toast-icon";
import css from "./toast.module.scss";

type Props = TToast & { id: string };

export const Toast = ({ type = "info", title, message, action, canClose = true, duration = 5000, id }: Props) => {
  const closeToast = useToast((state) => state.closeToast);

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setTimeout(() => {
        closeToast(id);
      }, 300);
    }
  };

  return (
    <ToastPrimitive.Root
      duration={duration}
      style={{ "--duration": `${Number.isInteger(duration) ? duration : 0}ms` } as React.CSSProperties}
      className={classNames(css.toast, css[`toast__${type}`], { [css.toast__message]: message })}
      onOpenChange={onOpenChange}
    >
      <ToastIcon className={css.titleIcon} type={type} />
      <ToastPrimitive.Title className={css.title}>{title}</ToastPrimitive.Title>
      {message && <ToastPrimitive.Description className={css.description}>{message}</ToastPrimitive.Description>}
      {canClose && (
        <ToastPrimitive.Close className={css.closeButton} asChild>
          <ButtonPrimitive>
            <NewIcon icon="close" width="18" height="18" />
          </ButtonPrimitive>
        </ToastPrimitive.Close>
      )}
      {action && (
        <div className={css.actions}>
          <ToastPrimitive.Action className={css.action} asChild altText={action.text}>
            <Button color="white" outlined className={css.actionButton} onClick={action.onClick}>
              {action.text}
            </Button>
          </ToastPrimitive.Action>
        </div>
      )}
    </ToastPrimitive.Root>
  );
};

export const ToastViewport = () => (
  <Container className={css.container}>
    <ToastPrimitive.Viewport className={css.viewport} />
  </Container>
);
