import React from "react";

import { Logo } from "ui/logo";
import { NewIcon } from "ui/NewIcon";

import css from "./menu-mobile-header.module.scss";

export function MenuMobileHeader({ children, onClose }: { children: React.ReactNode; onClose: () => void }) {
  return (
    <div className={css.header}>
      <Logo variant="light" className={css.logo} />
      <NewIcon className={css.close} icon="close" onClick={onClose} width="28" height="28" />
      {children}
    </div>
  );
}
