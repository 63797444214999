import algoliasearch, { SearchIndex } from "algoliasearch/lite";
// eslint-disable-next-line import/no-extraneous-dependencies
import { SearchOptions, SearchResponse } from "@algolia/client-search";

import type { Product, ProductList } from "resources/AudiotekaApi";

const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY);

interface SearchHit {
  catalog: string;
  content_language: string;
  country: string | null;
  deeplink: string;
  description: string | null;
  discount_price: string | null;
  id: string;
  image_url: string;
  is_available_in_subscription: boolean | null;
  is_free: boolean | null;
  name: string;
  objectID: string;
  price: string | null;
  price_for_subscribers: string | null;
  rating: number;
  shelf_only: boolean;
  slug: string;
  [key: string]: any;
}

export class SearchService {
  static parseHit = (hit: SearchHit): Product => ({
    id: hit.id,
    reference_id: hit.id,
    name: hit.name,
    image_url: hit.image_url,
    description: hit.description,
    rating: hit.rating,
    rating_count: null,
    is_free: hit.is_free,
    price: hit.price,
    discount_price: hit.discount_price,
    price_for_subscribers: hit.price_for_subscribers,
    lowest_price: hit.lowest_price || null,
    is_available_in_subscription: hit.is_available_in_subscription,
    deeplink: hit.deeplink,
    slug: hit.slug,
  });

  static parseResponse = (response: SearchResponse<SearchHit>): Omit<ProductList, "sort" | "order"> => ({
    page: response.page + 1, // agolia page starts from 0
    limit: response.hitsPerPage,
    pages: response.nbPages,
    total: response.nbHits,
    _embedded: {
      "app:product": response.hits.map((hit) => SearchService.parseHit(hit)),
    },
  });

  static deserialize = ({ restrict_facets = [], select_facets = [] }): string => {
    const orFacets = select_facets.map(({ name, value }) => `${name}:'${value}'`);
    const andFacets = restrict_facets.map(({ name, value }) => `${name}:'${value}'`);

    const orString = orFacets.join(" OR ");
    const andString = andFacets.join(" AND ");

    if (orString && andString) {
      return [
        orFacets.length > 1 ? `(${orString})` : orString,
        andFacets.length > 1 ? `(${andFacets})` : andFacets,
      ].join(" AND ");
    }
    if (orString) {
      return orString;
    }
    if (andString) {
      return andString;
    }

    return "";
  };

  private _filters: string = "";

  private _index: SearchIndex;

  private _indexListeners: ((value?: unknown) => void)[] = [];

  private awaitIndex = () =>
    new Promise((resolve) => {
      this._indexListeners.push(resolve);
    });

  public setConfig = ({ index, restrict_facets = [], select_facets = [] }) => {
    this._filters = SearchService.deserialize({ restrict_facets, select_facets });
    this._index = client.initIndex(index);

    let listener = this._indexListeners.shift();
    while (listener) {
      listener();
      listener = this._indexListeners.shift();
    }
  };

  public search = async (query: string, params: SearchOptions = {}): Promise<Omit<ProductList, "sort" | "order">> => {
    if (!this._index) {
      await this.awaitIndex();
    }

    const response = await this._index.search<SearchHit>(query, {
      filters: this._filters,
      ...params,
    });

    return SearchService.parseResponse(response);
  };
}

export default new SearchService();
