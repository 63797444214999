import React from "react";
import { Dialog } from "radix-ui";
import classNames from "classnames";

import { ButtonPrimitive } from "../button/button-primitive";
import { Text } from "../text";
import { NewIcon } from "../NewIcon";
import css from "./modal.module.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  dark?: boolean;
  size?: "regular" | "small";
  className?: string;
}

function Header({ children }: { children: React.ReactNode }) {
  return <div className={css.modalHeader}>{children}</div>;
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <Dialog.Title className={css.modalTitle} asChild>
      <Text type="heading5">{children}</Text>
    </Dialog.Title>
  );
}

function Description({ children }: { children: React.ReactNode }) {
  return (
    <Dialog.Description className={css.modalDescription} asChild>
      <Text type="bodyNormal">{children}</Text>
    </Dialog.Description>
  );
}

function Content({ children, className }: { children: React.ReactNode; className?: string }) {
  return <div className={className}>{children}</div>;
}

interface FooterProps {
  children: React.ReactNode;
  full?: boolean;
  className?: string;
}

function Footer({ children, full = false, className }: FooterProps) {
  return (
    <div
      className={classNames(
        css.modalFooter,
        {
          [css.modalFooter__full]: full,
        },
        className
      )}
    >
      {children}
    </div>
  );
}

export function Modal({ open, onClose, children, size = "regular", dark = false, className }: Props) {
  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className={classNames(css.dialogOverlay, className)}>
          <Dialog.Content
            className={classNames(css.dialogContent, {
              [css.dialogContent__dark]: dark,
              [css.dialogContent__small]: size === "small",
            })}
          >
            <Dialog.Close asChild>
              <ButtonPrimitive className={css.closeButton} onClick={onClose}>
                <NewIcon icon="close" width="24" height="24" />
              </ButtonPrimitive>
            </Dialog.Close>
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

Modal.Header = Header;
Modal.Title = Title;
Modal.Description = Description;
Modal.Content = Content;
Modal.Footer = Footer;
