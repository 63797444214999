import { parsePrice } from "utils/string";
import { Audiobook, Cart } from "resources/AudiotekaApi";

import { CartStoredItem } from "./cart.types";

export function convertProductsToCart(products: CartStoredItem[]): Cart {
  const currency = "PLN";

  let totalPrice = 0;
  let totalPriceBeforeDiscount = 0;

  const cartProducts = products.map(({ details }) => {
    totalPrice += details.final_user_price;
    totalPriceBeforeDiscount += details.price;

    return details;
  });

  return {
    cart_products: cartProducts,
    promocode: null,
    discounts: [],
    total_discount: totalPriceBeforeDiscount - totalPrice,
    total_price: totalPrice,
    total_price_before_discount: totalPriceBeforeDiscount,
    currency,
    _embedded: {
      "app:audiobooks": products.map(({ audiobook }) => audiobook) as any, // TODO: WWW-809 fix this type,
    },
  };
}

export function convertProductToCartItemDetails(product: Audiobook): Cart["cart_products"][number] {
  return {
    final_user_price: parsePrice(product.price) * 100,
    id: product.id,
    price: parsePrice(product.price) * 100,
    purchasable_state: "CAN_BE_PURCHASED",
    type: "audiobook",
  };
}

export function joinPriceWithCurrency(price: number, currency: string, locale: string) {
  return price.toLocaleString(locale, { style: "currency", currency });
}
