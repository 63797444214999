import type { User } from "resources/AudiotekaApi";

export interface UserDTO {
  id: string;
  email: string;
  hasMarketingConsent: boolean;
  isClubMember: boolean;
  trackingId: string;
  createdAt: string;
  lotteries: User["_embedded"]["app:lotteries"];
}

export class UserMapper {
  static toDTO(user: User): UserDTO {
    return {
      email: user.email,
      hasMarketingConsent: user.marketing_consent,
      id: user.id,
      isClubMember: user.has_club,
      trackingId: user.tracking_id,
      createdAt: user.created_at,
      lotteries: user._embedded?.["app:lotteries"],
    };
  }
}
