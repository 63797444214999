import React, { useRef } from "react";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { NewIcon } from "ui/NewIcon";
import type { Product } from "resources/AudiotekaApi";
import { useUser } from "store/useUser";
import { Tooltip } from "components/tooltip";

import { usePrice } from "./usePrice";
import css from "./teaser-footer.module.scss";

function ClubBadge({ product }: { product: Product }) {
  const user = useUser((state) => state.data);
  const { isFree, isAvailableInSubscription } = usePrice(product);
  const t = useTranslations("teaser");
  const badgeRef = useRef<SVGSVGElement>(null);

  const isBadgeVisible = isAvailableInSubscription || (isFree && user?.isClubMember);

  if (!isBadgeVisible) return null;

  return (
    <>
      <NewIcon
        accessibilityLabel={t("club_badge")}
        role="img"
        tabIndex={0}
        className={css.clubBadge}
        icon="club-circle-logo"
        width={18}
        height={18}
        ref={badgeRef}
      />
      <Tooltip trigger={badgeRef}>{t("club_badge")}</Tooltip>
    </>
  );
}

function CycleBadge() {
  const t = useTranslations("teaser");

  return (
    <NewIcon
      accessibilityLabel={t("cycle")}
      role="img"
      tabIndex={0}
      icon="cycle"
      width="24"
      height="24"
      className={css.cycleBadge}
    />
  );
}

function LowestPrice({ product }: { product: Product }) {
  const { basePrice, isClubPrice, finalPrice, lowestPrice } = usePrice(product);

  const t = useTranslations();

  const isLowestPrice = !isClubPrice && finalPrice && finalPrice !== basePrice && lowestPrice;

  if (!isLowestPrice) return null;

  return (
    <div className={css.lowestPrice}>{t("product.lowest_price", { price: lowestPrice.replace(/ /g, "\xa0") })}</div>
  );
}

function Price({ colorless, product }: { colorless?: boolean; product: Product }) {
  const { finalPrice, basePrice, isClubPrice } = usePrice(product);

  if (!finalPrice) return null;

  return (
    <p
      className={classNames(css.price, { [css.price__colorless]: colorless })}
      data-base-price={!isClubPrice && basePrice !== finalPrice ? basePrice : undefined}
    >
      {finalPrice}
    </p>
  );
}

function PriceWithClubBadge({ colorless, product }: { colorless?: boolean; product: Product }) {
  return (
    <div className={css.priceWithClubBadge}>
      <ClubBadge product={product} />
      <Price colorless={colorless} product={product} />
    </div>
  );
}

function Rating({ rating }: { rating: number | null }) {
  const t = useTranslations("teaser");

  if (!rating || rating < 1) return null;

  return (
    <div className={css.rating}>
      <NewIcon
        accessibilityLabel={t("rating", { rating })}
        role="img"
        tabIndex={0}
        icon="star"
        width="13"
        height="13"
      />
      {Math.floor(rating * 10) / 10}
    </div>
  );
}

function CycleOrRatingBadge({ product }: { product: Product }) {
  const isCycle = /\/cycle\//.test(product.deeplink);

  return isCycle ? <CycleBadge /> : <Rating rating={product.rating} />;
}

export const TeaserFooter = {
  ClubBadge,
  CycleOrRatingBadge,
  LowestPrice,
  PriceWithClubBadge,
  Rating,
};
