import { useLocale } from "next-intl";

import { buildLink } from "./buildLink";
import { useRouter } from "./next-router-provider";

export const useRouterPush = () => {
  const { push } = useRouter();
  const locale = useLocale();

  const routerPush = (pathname: Parameters<typeof buildLink>[0], params?: Parameters<typeof buildLink>[2]) =>
    push(buildLink(pathname, locale, params));

  return routerPush;
};
