import React, { ComponentProps } from "react";
import gtm from "react-gtm-module";
import { useLocale, useTranslations } from "next-intl";
import classNames from "classnames";

import { Button } from "ui/button";
import { ButtonLink } from "ui/button/button-link";
import { useUser } from "store/useUser";
import { Modal } from "ui/modal";
import { NewIcon } from "ui/NewIcon";
import { Text } from "ui/text";
import { useLaunchTestDomain } from "utils/useLaunchTestDomain";
import { getGTMProduct } from "utils/products";
import { getLicensesChannels } from "resources/AudiotekaApi";

import { useCart } from "../cart.hooks";
import { CartContext } from "../cart.types";
import { joinPriceWithCurrency } from "../cart.utils";
import css from "./cart-modal.module.scss";

interface CartModalProps extends Pick<ComponentProps<typeof Modal>, "onClose"> {
  cartItem: CartContext["recentItem"];
}

export const CartModal = ({ cartItem, onClose: closeModal }: CartModalProps) => {
  const { product, price } = cartItem || {};

  const t = useTranslations();
  const locale = useLocale();
  const { currency } = useCart();
  const user = useUser((state) => state.data);
  const launchTestDomain = useLaunchTestDomain();

  const handleBackToStore = () => {
    gtm.dataLayer({
      dataLayer: {
        event: "basket_info_modal",
        basket_info_modal_tab: "continue shopping",
      },
    });

    closeModal();
  };

  const handleGoToCart = async () => {
    gtm.dataLayer({
      dataLayer: {
        event: "basket_info_modal",
        basket_info_modal_tab: "go to cart",
      },
    });

    const licenses = user ? await getLicensesChannels(product.id).then((response) => response.data) : null;

    gtm.dataLayer({
      dataLayer: {
        event: "view_cart",
        ecommerce: {
          currency,
          value: price / 100,
          items: [
            getGTMProduct({
              audiobook: product,
              isClubMember: user?.isClubMember || false,
              licenses,
              product,
            }),
          ],
        },
      },
    });

    closeModal();
  };

  return (
    <Modal open={!!product} onClose={closeModal}>
      <Modal.Title>
        <NewIcon className={css.titleIcon} icon="check" width="20" height="16" />
        {t("cart.recent_item.title")}
      </Modal.Title>
      <Modal.Content className={css.content}>
        <img className={css.cover} src={`${product.image_url}?w=300&auto=format`} alt={product.name} />
        <div className={css.info}>
          <Text title={product.name} type="subtitleBig" className={classNames(css.title, css.lineClamp)}>
            {product.name}
          </Text>
          <Text type="bodyBig" className={classNames(css.author, css.lineClamp)}>
            {product._embedded["app:author"].map((a) => a.name).join(", ")}
          </Text>
        </div>
        <Text type="subtitleBig" className={css.price}>
          {joinPriceWithCurrency(price / 100, currency, locale as string)}
        </Text>
      </Modal.Content>
      <Modal.Footer full>
        <Button onClick={handleBackToStore} outlined>
          {t("cart.recent_item.back_to_store")}
        </Button>
        <ButtonLink href={`${launchTestDomain}/[locale]/cart`} onClick={handleGoToCart}>
          {t("cart.go_to_cart")}
        </ButtonLink>
      </Modal.Footer>
    </Modal>
  );
};
