import React, { forwardRef, ComponentType, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from "react";
import classNames from "classnames";

import { ResponsiveProps, WithBoxModelProps } from "./withBoxModel.types";
import css from "./withBoxModel.module.scss";

const getWithBoxModelStyles = ({ m, mx, my, mt, mb, ml, mr, p, px, py, pt, pb, pl, pr }: WithBoxModelProps) => {
  const classes: string[] = [];
  const variables: Record<string, string> = {};

  const handleSpacing = (prop: string | ResponsiveProps<string>, className: string, cssVariable: string) => {
    if (typeof prop === "string") {
      classes.push(css[className]);
      variables[`--${cssVariable}`] = prop;
    } else if (typeof prop === "object") {
      if (prop._) {
        classes.push(css[className]);
        variables[`--${cssVariable}`] = prop._;
      }
      if (prop.tablet) {
        classes.push(css[`${className}-tablet`]);
        variables[`--${cssVariable}-tablet`] = prop.tablet;
      }
      if (prop.laptop) {
        classes.push(css[`${className}-laptop`]);
        variables[`--${cssVariable}-laptop`] = prop.laptop;
      }
      if (prop.desktop) {
        classes.push(css[`${className}-desktop`]);
        variables[`--${cssVariable}-desktop`] = prop.desktop;
      }
    }
  };

  handleSpacing(m, "m", "margin");
  handleSpacing(mx, "mx", "margin-x");
  handleSpacing(my, "my", "margin-y");
  handleSpacing(mt, "mt", "margin-top");
  handleSpacing(mb, "mb", "margin-bottom");
  handleSpacing(ml, "ml", "margin-left");
  handleSpacing(mr, "mr", "margin-right");

  handleSpacing(p, "p", "padding");
  handleSpacing(px, "px", "padding-x");
  handleSpacing(py, "py", "padding-y");
  handleSpacing(pt, "pt", "padding-top");
  handleSpacing(pb, "pb", "padding-bottom");
  handleSpacing(pl, "pl", "padding-left");
  handleSpacing(pr, "pr", "padding-right");

  return { classes, variables };
};

export function withBoxModel<T extends object>(
  Component: ComponentType<PropsWithoutRef<T>>
): ForwardRefExoticComponent<PropsWithoutRef<T & WithBoxModelProps> & RefAttributes<HTMLElement>> {
  const WrappedComponent = Component;

  WrappedComponent.displayName = `WithBoxModel(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return forwardRef<HTMLElement, T & WithBoxModelProps>((props, ref) => {
    const { m, mx, my, mt, mb, ml, mr, p, px, py, pt, pb, pl, pr, ...componentSpecificProps } = props;

    const { classes, variables } = getWithBoxModelStyles({
      m,
      mx,
      my,
      mt,
      mb,
      ml,
      mr,
      p,
      px,
      py,
      pt,
      pb,
      pl,
      pr,
    });

    return (
      <WrappedComponent
        {...(componentSpecificProps as PropsWithoutRef<T>)}
        style={{ ...props.style, ...variables }}
        className={classNames(classes, props.className)}
        ref={ref}
      />
    );
  });
}
