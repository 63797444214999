import React, { useState, PropsWithChildren } from "react";
import { useRouter } from "next/router";
import { NextIntlClientProvider } from "next-intl";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// eslint-disable-next-line import/no-unresolved
import { ReCaptchaProvider } from "next-recaptcha-v3";

import ConfigProvider from "modules/Config/ConfigProvider";
import { TeaserTrackingProvider } from "modules/teaser-tracking";
import { CartProvider } from "modules/cart/provider";
import { FavouritesProvider } from "modules/favourites/favourites.provider";
import { PlayerProvider } from "modules/player";
import NextRouterProvider from "utils/next-router-provider";
import { Locale } from "config";

import ApplicationStateProvider from "./store/ApplicationStateProvider";

export const AppProviders = ({ children, messages }: PropsWithChildren<{ messages: any }>) => {
  // https://tanstack.com/query/latest/docs/framework/react/guides/ssr
  const [queryClient] = useState(() => new QueryClient());
  const {
    query: { locale },
  } = useRouter();

  return (
    <NextIntlClientProvider locale={locale as Locale} messages={messages} timeZone="Europe/Warsaw">
      <NextRouterProvider value="page">
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ApplicationStateProvider>
            <ConfigProvider>
              <ReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY} useEnterprise>
                <FavouritesProvider>
                  <PlayerProvider>
                    <CartProvider>
                      <TeaserTrackingProvider>{children}</TeaserTrackingProvider>
                    </CartProvider>
                  </PlayerProvider>
                </FavouritesProvider>
              </ReCaptchaProvider>
            </ConfigProvider>
          </ApplicationStateProvider>
        </QueryClientProvider>
      </NextRouterProvider>
    </NextIntlClientProvider>
  );
};
