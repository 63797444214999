import React, { type SVGProps } from "react";

export const ToastIcon = ({
  type,
  ...props
}: SVGProps<SVGSVGElement> & { type: "info" | "success" | "warning" | "error" }) => {
  return (
    <svg width={20} height={20} {...props}>
      <use href={`/img/toast-icon-210325.svg#${type}`} />
    </svg>
  );
};
