import React from "react";

import { DefaultTeaser } from "components/teaser";
import TeaserList from "components/teaser-list";
import type { Product } from "resources/AudiotekaApi";

import css from "./search-results.module.scss";

interface Props {
  limit?: number;
  mobileLimit?: number;
  results: Product[];
}

export const SearchResults = ({ limit, mobileLimit, results }: Props) => {
  const desktopResults = limit ? results.slice(0, limit) : results;
  const mobileResults = mobileLimit ? results.slice(0, mobileLimit) : results;

  return (
    <div>
      <div className={css.mobileGrid}>
        {mobileResults.map((product) => (
          <DefaultTeaser key={product.id} product={product} />
        ))}
      </div>
      <TeaserList className={css.teaserList} elements={desktopResults} fluid />
    </div>
  );
};
