import { create } from "zustand";

import { userSyncChannel } from "utils/userSyncChannel";

import { UserState } from "./types";
import { shouldBeAlphaTester } from "./utils";
import { UserMapper } from "./useUser.DTO";

export const useUser = create<UserState>((setState, getState) => ({
  data: null,
  isAlphaTester: false,
  status: "idle",

  /**
   * Set user state based on API user object.
   * shouldSyncUser is used in uncontrolled situations
   * like initial user loading or user sync with BroadcastChannel.
   * @param user
   * @param shouldSyncUser
   */
  setUser(user, shouldSyncUser = true) {
    setState({
      data: user ? UserMapper.toDTO(user) : null,
      isAlphaTester: shouldBeAlphaTester(),
      status: "fetched",
    });

    if (shouldSyncUser) {
      userSyncChannel.postMessage(user ? { actionType: "signIn", user } : { actionType: "signOut" });
    }
  },

  setStatus(status) {
    setState({ status });
  },

  updateUser(user, shoudSyncUser = true) {
    const currentUser = getState().data;
    const updatedUser = currentUser
      ? {
          ...currentUser,
          ...user,
        }
      : null;

    setState({ data: updatedUser });

    if (shoudSyncUser && updatedUser) {
      userSyncChannel.postMessage({ actionType: "update", user });
    }
  },
}));
