import gtm from "react-gtm-module";
import { Audiobook, Cart } from "resources/AudiotekaApi";

interface AddToCartPayload {
  cart: Cart;
  cartItemIndex: number;
  cartItemDetails: Cart["cart_products"][number];
  product: Audiobook;
}

export function trackAddToCartEvent({ cart, cartItemIndex, cartItemDetails, product }: AddToCartPayload) {
  gtm.dataLayer({
    dataLayer: {
      event: "add_to_cart",
      ecommerce: {
        currency: cart.currency,
        value: cart.total_price / 100,
        items: [
          {
            coupon: cart.promocode,
            discount: (cartItemDetails.final_user_price - cartItemDetails.price) / 100,
            index: cartItemIndex,
            item_id: cartItemDetails.id,
            item_name: product.name,
            item_variant: "one-off",
            price: cartItemDetails.final_user_price / 100,
            quantity: 1,
          },
        ],
      },
    },
  });
}
