import { HomeScreen } from "resources/AudiotekaApi";

export const HS_SET_STATE = "HS_SET_STATE";

export function setHomeScreenState(payload: HomeScreen) {
  return {
    type: HS_SET_STATE,
    payload,
  };
}
