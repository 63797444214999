import React from "react";
import classNames from "classnames";

import css from "./loader.module.scss";

export const Loader = ({ className }: { className?: string }) => {
  return (
    <span className={classNames(css.loader, className)}>
      <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.00146 27C4.00146 20.9 6.42467 15.0499 10.738 10.7365C15.0513 6.42321 20.9015 4 27.0015 4C33.1014 4 38.9516 6.42321 43.2649 10.7365C47.5782 15.0499 50.0014 20.9 50.0014 27"
          stroke="url(#paint0_linear_19482_8098)"
          strokeWidth="8"
        />
        <path
          d="M50.0014 27C50.0014 33.1 47.5782 38.9501 43.2649 43.2635C38.9516 47.5768 33.1014 50 27.0015 50C20.9015 50 15.0513 47.5768 10.738 43.2635C6.42467 38.9501 4.00146 33.1 4.00146 27"
          stroke="url(#paint1_linear_19482_8098)"
          strokeWidth="8"
        />
        <path
          d="M4.00125 27C3.99958 26.8403 3.99958 26.6806 4.00125 26.5209"
          stroke="currentColor"
          strokeWidth="8"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_19482_8098"
            x1="4.00146"
            y1="4"
            x2="50.0014"
            y2="4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" stopOpacity="0" />
            <stop offset="1" stopColor="currentColor" stopOpacity="0.5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_19482_8098"
            x1="4.00146"
            y1="27"
            x2="50.0014"
            y2="27"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="currentColor" />
            <stop offset="1" stopColor="currentColor" stopOpacity="0.5" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};
