import React, { PropsWithChildren } from "react";

import { useUser } from "store/useUser";

import { CartProvider as NewCartProvider } from "./cart.provider";
import { OldCartProvider } from "./old-cart.provider";

export function CartProvider({ children }: PropsWithChildren) {
  const isAlphaTester = useUser((state) => state.isAlphaTester);

  if (isAlphaTester) {
    return <NewCartProvider>{children}</NewCartProvider>;
  }

  return <OldCartProvider>{children}</OldCartProvider>;
}
