"use client";

import type shaka from "shaka-player";
import React, { ComponentProps, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import * as Sentry from "@sentry/nextjs";
import api, { HalController } from "api-web-client";
import debounce from "lodash/debounce";
import { v4 as generateId } from "uuid";
import { useLocale, useTranslations } from "next-intl";

import { useConfig } from "modules/Config/useConfig";
import { useToast } from "store/useToast";
import { useUser } from "store/useUser";
import { browserName } from "utils/runtime";
import { getAudiobookMedia, getPlayer } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";

import { playerConfig } from "./player.config";
import { PlayerContext, playerContext, PlayerLoadOptions, SetNumberFn } from "./player.context";
import { PlayerSettings, playerStore } from "./player.store";
import { sendGTMPlayEvent } from "./player.utils";
import _player from "./player";

const Player = dynamic(() => import("./components/Player"), { ssr: false });

const _windowId: string = generateId();

export const PlayerProvider = ({ children }: PropsWithChildren) => {
  const player = useRef(_player);
  const settings = useRef<PlayerSettings | null>(null);
  const playingInterval = useRef<number | NodeJS.Timeout | null>(null);
  const stopPlaybackToast = useRef<string | null>(null);
  const playbackPreviousTime = useRef<number | null>(null);
  const playbackStartTime = useRef<number>(0);
  const pauseReporting = useRef<boolean>(false);

  const user = useUser((state) => state.data);
  const config = useConfig();
  const t = useTranslations();
  const locale = useLocale();
  const toast = useToast();

  const [audiobook, setAudiobook] = useState<PlayerContext["audiobook"] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [activeModal, setActiveModal] = useState<ComponentProps<typeof Player>["activeModal"]>(null);
  const [areComponentsMounted, setAreComponentsMounted] = useState(false);
  const [audio, setAudio] = useState<PlayerContext["audio"]>({
    currentTime: 0,
    duration: 0,
    hasEnded: false,
    isMuted: false,
    isPlaying: false,
    playbackRate: 1,
    volume: 1,
  });

  const saveProgress = useCallback(async () => {
    if (!user?.id) return;

    const progress = Math.floor(player.current.currentTime);
    const progressInMs = Math.floor(player.current.currentTime * 1000);

    if (audiobook?.progress === progress) {
      return; // Don't save the same progress
    }

    try {
      await Promise.all([
        playerStore.saveProgress(audiobook.id, progress),
        api.sendCommand("SavePlaybackProgress", {
          audiobook_id: audiobook.id,
          position: progressInMs,
          device: "Web Player",
        }),
      ]);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [audiobook?.id, audiobook?.progress, user?.id]);

  const reportPlaybackTime = useCallback(
    async (endTime = Math.floor(player.current.currentTime)) => {
      const startTime = playbackStartTime.current;
      const diff = endTime - startTime;

      playbackStartTime.current = Math.floor(player.current.currentTime);

      if (pauseReporting.current || diff < 5) {
        return; // Don't report time shorter than 5 seconds
      }

      try {
        await api.sendCommand("SavePlaybackTime", {
          audiobook_id: audiobook.id,
          mediaId: player.current.mediaId,
          started_at: Math.floor(startTime),
          stopped_at: Math.ceil(endTime),
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [audiobook?.id]
  );

  const reportProgressAndPlayback = useCallback(() => {
    saveProgress();
    reportPlaybackTime();
  }, [saveProgress, reportPlaybackTime]);

  const reloadPlayer = useCallback(
    debounce(async () => {
      const audiobookId = player.current.audiobook?.id;

      player.current.pause();

      if (audiobookId) {
        await player.current.loadBySlugOrId(audiobookId);
      }
    }, 500),
    []
  );

  useEffect(() => {
    reloadPlayer();
  }, [user?.id, reloadPlayer]);

  useEffect(() => {
    playerStore.getSettings().then((_settings) => {
      settings.current = _settings;
      player.current.muted = _settings.muted;
      player.current.volume = _settings.volume;
      player.current.defaultPlaybackRate = _settings.playbackRate;
      player.current.playbackRate = _settings.playbackRate;
    });

    const handleLocalStorageChange = ({ key, newValue }: StorageEvent) => {
      if (key === "player-window-id" && newValue !== _windowId) {
        player.current.pause();
      }
    };

    window.addEventListener("storage", handleLocalStorageChange);

    api.subscribe(({ isCommand, response }) => {
      if (isCommand && response.status === 401) {
        reloadPlayer();
      }
    });
    return () => {
      reportProgressAndPlayback();
    };
  }, []);

  const checkStopPlayback = async () => {
    const { data: playerData } = await getPlayer();

    if (playerData.stop_playback) {
      player.current.pause();

      if (stopPlaybackToast.current) {
        toast.closeToast(stopPlaybackToast.current);
      }

      const toastId = (Date.now() * Math.random()).toString();

      stopPlaybackToast.current = toastId;

      toast.showToast({
        title: t("player.stop_playback.title"),
        message: t("player.stop_playback.message", { title: playerData.audiobook_name }),
        type: "warning",
      });
    }
  };

  const updateAudio = (updated: Partial<PlayerContext["audio"]>) => {
    setAudio((prevAudio) => ({
      ...prevAudio,
      ...updated,
    }));
  };

  const updateAudiobook = (updated: Partial<PlayerContext["audiobook"]>) => {
    setAudiobook((prevAudiobook) => ({
      ...prevAudiobook,
      ...updated,
    }));
  };

  const updateSettings = async (_settings: Partial<PlayerSettings>) => {
    settings.current = {
      ...settings.current,
      ..._settings,
    };

    await playerStore.saveSettings(_settings);
  };

  const load = async (halAudiobook: HalController | string, options: PlayerLoadOptions = {}) => {
    setAreComponentsMounted(true);

    if (typeof halAudiobook === "string") {
      return player.current.loadBySlugOrId(halAudiobook, options);
    }

    return player.current.load(halAudiobook, options);
  };

  const unload = () => {
    saveProgress();
    player.current.unload();
  };

  const setPlaybackRate = (playbackRate: number) => {
    updateSettings({ playbackRate });
    player.current.playbackRate = playbackRate;
  };

  const setTime = (time: number | SetNumberFn) => {
    const newTime = typeof time === "function" ? time(player.current.currentTime) : time;

    updateAudio({ currentTime: newTime });

    player.current.currentTime = newTime;
  };

  const setVolume = (volume: number | SetNumberFn) => {
    const newVolume = Math.round((typeof volume === "function" ? volume(player.current.volume) : volume) * 100) / 100;

    player.current.volume = Math.max(0, Math.min(1, newVolume));
  };

  const togglePlay = () => {
    if (player.current.paused) {
      player.current.play();
    } else {
      player.current.pause();
    }
  };

  const pauseAndReporting = () => {
    reportPlaybackTime();
    pauseReporting.current = true;
  };

  const resumeReporting = () => {
    playbackStartTime.current = Math.floor(player.current.currentTime);
    pauseReporting.current = false;
  };

  // event handlers

  const handlePlay = useCallback(async () => {
    sendGTMPlayEvent(audiobook);

    localStorage.setItem("player-window-id", _windowId);
    window.onbeforeunload = reportProgressAndPlayback;

    updateAudio({ isPlaying: true });

    if (stopPlaybackToast.current) {
      toast.closeToast(stopPlaybackToast.current);
      stopPlaybackToast.current = null;
    }

    if (user) {
      await api.sendCommand("StartPlayback", {
        audiobook_id: audiobook.id,
      });

      if (playingInterval.current) {
        clearInterval(playingInterval.current);
      }

      playingInterval.current = setInterval(() => {
        Promise.all([checkStopPlayback(), saveProgress()]);
      }, config.player_poll_interval_in_seconds * 1000);
    }
  }, [audiobook, user]);

  const handleVolumeChange = useCallback(() => {
    updateAudio({
      isMuted: player.current.muted,
      volume: player.current.volume,
    });

    updateSettings({
      muted: player.current.muted,
      volume: player.current.volume,
    });
  }, []);

  const handleRateChange = useCallback(() => {
    updateAudio({ playbackRate: player.current.playbackRate });
  }, []);

  const handleTimeUpdate = useCallback(() => {
    // Math.floor to cut milliseconds for lower update rate
    const currentTime = Math.floor(player.current.currentTime);

    if (player.current.paused) {
      playbackStartTime.current = currentTime;
    } else if (Math.abs(currentTime - playbackPreviousTime.current) > 1) {
      // Jump longer than 1 second - report playback time
      reportPlaybackTime(playbackPreviousTime.current);
    }

    updateAudio({
      currentTime,
      hasEnded: currentTime >= Math.floor(player.current.duration),
    });

    playbackPreviousTime.current = currentTime;
  }, []);

  const handleEnded = useCallback(async () => {
    clearInterval(playingInterval.current);

    updateAudio({ hasEnded: true });
    saveProgress();
    reportPlaybackTime();

    if (audiobook?.isSample) {
      try {
        const { catalogId } = getLocationInfo(locale);
        const { data: media } = await getAudiobookMedia(audiobook.id, catalogId);

        const stream = browserName === "Safari" ? media.hls : media.dash;

        if (!stream) {
          setActiveModal("no_drm");
          return;
        }
      } catch (e) {
        Sentry.captureException(`PlayerProvider | Failed to get audiobook media | Error: ${e}`);
      }

      setActiveModal(audiobook?.isBasicPlanLimited ? "basic_plan_limit" : "sample_end");
    }
  }, [audiobook?.isBasicPlanLimited, audiobook?.isSample]);

  const handleLoadedMetaData = useCallback(() => {
    updateAudiobook({
      ...player.current.audiobook,
      chapters: player.current.chapters,
    });
    updateAudio({ duration: Math.floor(player.current.duration) });

    player.current.playbackRate = settings.current.playbackRate;

    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = player.current.audiobook
        ? new MediaMetadata({
            title: player.current.audiobook.title,
            artist: player.current.audiobook.authors.map((author) => author.name).join(", "),
            artwork: playerConfig.mediaCoverSizes.map((size) => {
              const src = new URL(player.current.audiobook.cover);
              src.searchParams.set("w", String(size));

              return { src: src.toString(), sizes: `${size}x${size}` };
            }),
          })
        : null;
    }
  }, []);

  const handleLoadingStateChange = useCallback(() => {
    setIsLoading(player.current.isLoading);
  }, []);

  const handleUnloaded = useCallback(() => {
    clearInterval(playingInterval.current);

    setAudiobook(null);
  }, []);

  const handlePlayerError = useCallback((error: CustomEvent<shaka.util.Error> | Event) => {
    player.current.unload();

    const licenseRequestFailed: shaka.util.Error.Code.LICENSE_REQUEST_FAILED = 6007;

    if ("detail" in error && error.detail.code === licenseRequestFailed && error.detail.data[0]?.data[1] === 403) {
      // License request failed with 403 error
      setActiveModal("basic_plan_limit");
    } else {
      Sentry.captureException(error);
      setActiveModal("error");
    }
  }, []);

  const handleCanPlay = useCallback(() => {
    playbackStartTime.current = Math.floor(player.current.currentTime);
  }, []);

  const handlePause = useCallback(() => {
    window.onbeforeunload = null;

    clearInterval(playingInterval.current);
    updateAudio({ isPlaying: false });

    reportProgressAndPlayback();
  }, [reportProgressAndPlayback]);

  useEffect(() => {
    player.current.on("play", handlePlay);
    player.current.on("pause", handlePause);
    player.current.on("volumechange", handleVolumeChange);
    player.current.on("ratechange", handleRateChange);
    player.current.on("timeupdate", handleTimeUpdate);
    player.current.on("ended", handleEnded);
    player.current.on("loadedmetadata", handleLoadedMetaData);
    player.current.on("loadingstatechange", handleLoadingStateChange);
    player.current.on("unloaded", handleUnloaded);
    player.current.on("error", handlePlayerError);
    player.current.on("canplay", handleCanPlay);

    return () => {
      player.current.off("play", handlePlay);
      player.current.off("pause", handlePause);
      player.current.off("volumechange", handleVolumeChange);
      player.current.off("ratechange", handleRateChange);
      player.current.off("timeupdate", handleTimeUpdate);
      player.current.off("ended", handleEnded);
      player.current.off("loadedmetadata", handleLoadedMetaData);
      player.current.off("loadingstatechange", handleLoadingStateChange);
      player.current.off("unloaded", handleUnloaded);
      player.current.off("error", handlePlayerError);
      player.current.off("canplay", handleCanPlay);
    };
  }, [
    handleCanPlay,
    handleEnded,
    handleLoadedMetaData,
    handlePlayerError,
    handlePlay,
    handleTimeUpdate,
    handleUnloaded,
    handleVolumeChange,
    handleRateChange,
    handleLoadingStateChange,
    handlePause,
  ]);

  const onCloseModal = useCallback(() => {
    setActiveModal(null);
    setIsLoading(false);
  }, []);

  return (
    <playerContext.Provider
      value={{
        audio,
        audiobook,
        isLoading,
        isMaximized,
        load,
        minimize: () => setIsMaximized(false),
        play: player.current.play,
        pause: player.current.pause,
        pauseReporting: pauseAndReporting,
        resumeReporting,
        setPlaybackRate,
        setTime,
        setVolume,
        toggleMaximize: () => setIsMaximized((value) => !value),
        toggleMute: () => {
          player.current.muted = !player.current.muted;
        },
        togglePlay,
        unload,
      }}
    >
      {children}
      {areComponentsMounted && <Player onCloseModal={onCloseModal} activeModal={activeModal} />}
    </playerContext.Provider>
  );
};
