export function stringifyPrice(price) {
  return price.toFixed(2).replace(".", ",");
}

export function parsePrice(price) {
  return parseFloat(price.replace(/^.*?(\d+,\d+).*?$/, "$1").replace(",", "."));
}

export function isId(slugOrId) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(slugOrId);
}
